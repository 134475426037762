.dark_background_modal .ant-form-item-label > label{
    color: #B2B2B2 !important;
    font-size: 12px!important;
    font-weight:500!important;
}



.passwordstate {
    display: flex;
    gap: 5px;
    margin: 5px 0;
}

.passwordstate > span {
    height: 4px;
    background-color: red;
    flex: 1;
    border-radius: 2px;
}

.passwordstate > span.activepass {
    background-color: green;
}

.alertmessage {
    margin-bottom: 15px;
}

.alertmessage .ant-alert-error .ant-alert-message {
    color: red !important;
}

.alertmessage .ant-alert-success .ant-alert-message {
    color: green !important;
}